import { Fragment } from 'react'
import { isMobile } from 'react-device-detect'
import { Cross } from '@components/icons'
import { Dialog, Transition } from '@headlessui/react'
import Link from 'next/link'
import Router from 'next/router'

export const ConvenienceFee = ({
  showConvenienceFee,
  setConvenienceFee,
  title,
  description,
}: any) => {
  return (
    <Transition.Root show={showConvenienceFee} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-99"
        onClose={() => {
          setConvenienceFee(false)
        }}
      >
        <div className="fixed inset-0 left-0 bg-orange-600/0">
          <div className="fixed inset-0 overflow-hidden">
            <div
              className="absolute inset-0 overflow-hidden"
              style={{ background: 'rgba(106, 52, 31, 0.4)' }}
            >
              <div
                className={`fixed ${
                  isMobile ? 'top-auto bottom-0' : 'inset-y-0'
                } right-0 flex max-w-full pl-0 pointer-events-none bottom-to-top sm:pl-0`}
              >
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-200 sm:duration-200"
                  enterFrom="translate-y-full"
                  enterTo="translate-y-0"
                  leave="transform transition ease-in-out duration-200 sm:duration-200"
                  leaveFrom="translate-y-0"
                  leaveTo="translate-y-full"
                >
                  <Dialog.Panel className="w-screen pointer-events-auto">
                    <div className="relative flex flex-col h-full shadow-xl bg-orange-900/20 z-99">
                      <div
                        className="w-full h-auto max-w-md shadow-xl mx-auto bg-white center-fix-panel"
                        style={{
                          boxShadow: '0px -4px 4px 0px rgba(0, 0, 0, 0.04)',
                        }}
                      >
                        <div className="sticky top-0 z-10 pt-[20px] pb-[16px] px-5  border-b border-gray-200">
                          <div className="flex justify-between">
                            <h3 className="text-base font-semibold text-black">
                              {title}
                            </h3>

                            <button
                              type="button"
                              className="mr-2 text-black rounded-md outline-none hover:text-gray-500"
                              onClick={() => setConvenienceFee(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <Cross
                                className="relative top-0 w-6 h-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>

                        <div className="flex flex-col px-5 py-5">
                          <p
                            className="font-light text-xs"
                            style={{ color: '#251000a3' }}
                          >
                            {description}
                          </p>
                          <p
                            className="font-light text-xs pt-2.5"
                            style={{ color: '#251000a3' }}
                          >
                            Head over to the{' '}
                            <span
                              style={{ color: '#EC5E28' }}
                              className="cursor-pointer"
                              onClick={() => {
                                setConvenienceFee(false)
                                setTimeout(() => {
                                  Router?.push('/terms-and-conditions')
                                }, 800)
                              }}
                            >
                              T&C
                            </span>{' '}
                            and{' '}
                            <span
                              style={{ color: '#EC5E28' }}
                              className="cursor-pointer"
                              onClick={() => {
                                setConvenienceFee(false)
                                setTimeout(() => {
                                  Router?.push('/faq')
                                }, 800)
                              }}
                            >
                              FAQ
                            </span>{' '}
                            section to find out more.
                          </p>
                        </div>

                        <div className="flex flex-col px-5 pb-5">
                          <button
                            type="button"
                            className="w-full text-sm font-semibold text-white bg-black border border-black btn-basic-property hover:text-white hover:bg-gray-800"
                            onClick={() => setConvenienceFee(false)}
                          >
                            Got it
                          </button>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
