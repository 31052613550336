import { useState } from 'react'
import attributesGenerator, {
  getAttributesFromSlug,
  productLookup,
} from '@components/utils/attributesGenerator'
import dynamic from 'next/dynamic'

const SizeInline = dynamic(() => import('./SizeInline'), { ssr: false })
const ProductColors = dynamic(() => import('./ProductColorsPDP'), {
  ssr: false,
})

const ATTR_COMPONENTS: any = {
  ProductColors: (props: any) => <ProductColors {...props} />,
  SizeInline: (props: any) => <SizeInline {...props} hideMRP={true} />,
  undefined: () => null,
}

const TEMP_MAP: any = {
  'clothing.size': ATTR_COMPONENTS['SizeInline'],
  'global.colour': ATTR_COMPONENTS['ProductColors'],
}

export default function AttributesHandler({
  product,
  setSelectedAttrData,
  variant,
  onChange = () => {},
  allowCart = true,
  callToAction = null,
  currentVariantData,
  forInputSelection = false,
  selectedValue = null,
  setQuickview = null,
  currentPage,
  deviceInfo,
  selectedSizeProduct,
  selectedSizeProductDiscount,
}: any) {
  const { variantAttributes = [], variantProducts } = product

  const originalAttributes = getAttributesFromSlug(
    product?.link,
    variantProducts
  )

  const generatedAttrCombination: any = originalAttributes
    ? Object.fromEntries(Object.entries(originalAttributes)?.slice(0, 1))
    : null
  const [attrCombination, setAttrCombination] = useState(
    generatedAttrCombination
  )

  const generateLink = (fieldCode: any, value: any) => {
    let slug = ''
    variantProducts.find((item: any) => {
      item.attributes.find((option: any) => {
        const isFieldCode = option.fieldCode === fieldCode
        const isFieldValue = option.fieldValue === value
        if (isFieldCode && isFieldValue) {
          slug = item.slug
        }
      })
    })
    return slug
  }

  const handleChange = (fieldCode: string, value: string, obj?: any) => {
    onChange(fieldCode, value, obj)
    /*const slug = generateLink(fieldCode, value)
    if (slug) {
      router.push(`/${slug}`)
    }*/
  }

  const getStockPerAttribute = (key: string, variant: string) => {
    let productData = {
      stock: 0,
      productId: '',
      isPreOrderEnabled: false,
      sellWithoutInventory: false,
      stockCode: '',
    }
    // const slug = `${PRODUCTS_ROUTE_PREFIX}${router.query.slug}`
    variantProducts.find((product: any) => {
      product.attributes.forEach((attr: any) => {
        if (
          key.toLowerCase() === attr.fieldCode.toLowerCase() &&
          attr.fieldValue === variant
          // product.slug === slug
        ) {
          productData.stock = product.currentStock
          productData = { ...productData, ...product }
        }
      })
    })
    return productData
  }

  const variantProductFilter = (vps: any[], fc: string, v: string) => {
    const checkCorrectAttribute = (vpas: any) =>
      vpas.filter((vpa: any) => vpa.fieldCode === fc && vpa.fieldValue === v)
    const optionsWithCorrectAttribute = vps.filter(
      (vp: any) => checkCorrectAttribute(vp.attributes).length > 0
    )
    return optionsWithCorrectAttribute
  }

  const getCorrectStockPerAttribute = (key: string, variant: string) => {
    let productData = {
      stock: 0,
      productId: '',
      isPreOrderEnabled: false,
      sellWithoutInventory: false,
      stockCode: '',
    }

    if ((key = 'clothing.size')) {
      const selectedColor = product?.customAttributes?.filter(
        (attr: any) => attr?.key === 'global.colour'
      )[0]?.value
      const variantsThatMatchColor = variantProductFilter(
        variantProducts,
        'global.colour',
        selectedColor
      )
      const variantsThatMatchColorAndSize = variantProductFilter(
        variantsThatMatchColor,
        key,
        variant
      )[0]
      productData.stock = variantsThatMatchColorAndSize?.currentStock ?? 0
      productData = { ...productData, ...product }
      return productData
    }

    if ((key = 'global.colour')) {
      const selectedSize = product?.customAttributes?.filter(
        (attr: any) => attr?.key === 'global.size'
      )[0]?.value
      const variantsThatMatchSize = variantProductFilter(
        variantProducts,
        'clothing.size',
        selectedSize
      )
      const variantsThatMatchSizeAndColor = variantProductFilter(
        variantsThatMatchSize,
        key,
        variant
      )[0]
      productData.stock = variantsThatMatchSizeAndColor?.currentStock ?? 0
      productData = { ...productData, ...product }
      return productData
    }

    // Array.find method returns the first value that matches the find criteria. Code below does check for only one attribute key(either size or color) so it will give wrong stocks.
    else {
      variantProducts.find((product: any) => {
        product.attributes.forEach((attr: any) => {
          if (
            key.toLowerCase() === attr.fieldCode.toLowerCase() &&
            attr.fieldValue === variant
            // product.slug === slug
          ) {
            productData.stock = product.currentStock
            productData = { ...productData, ...product }
          }
        })
      })
      return productData
    }
  }

  //temporary until DisplayTemplate is implemented
  const isCustomAttr = product.variantAttributes?.length > 2

  const generateOptions = (option: any) => {
    let isInOrder = true
    let isLastItem = false

    if (!attrCombination) {
      isInOrder =
        Object.keys(originalAttributes)?.findIndex(
          (i: string) => i === option?.fieldCode
        ) - attrCombination
          ? Object.keys(attrCombination)?.length === 0 ||
            Object.keys(attrCombination)?.includes(option?.fieldCode)
          : false

      isLastItem = attrCombination
        ? Object.keys(attrCombination).pop() === option.fieldCode
        : false
    } else {
      isInOrder =
        Object.keys(originalAttributes).findIndex(
          (i: string) => i === option?.fieldCode
        ) -
          Object.keys(attrCombination).length ===
          0 || Object.keys(attrCombination).includes(option?.fieldCode)

      isLastItem = Object.keys(attrCombination).pop() === option.fieldCode
    }

    if (isInOrder) {
      if (
        isCustomAttr &&
        Object.keys(attrCombination).length > 1 &&
        !isLastItem
      ) {
        const entriesFromCombination = () => {
          return Object.fromEntries(Object.entries(attrCombination).slice(-1))
        }
        const generatedAttributes = attributesGenerator(
          entriesFromCombination(),
          variantProducts
        )
        return generatedAttributes
          .map((item: any) => {
            if (option?.fieldCode === item?.fieldCode) {
              return item
            }
          })
          .filter((el) => el)
      } else {
        return option?.fieldValues
      }
    } else {
      return []
    }
  }

  const handleAttrCombinations = (key: string, value: any) => {
    setAttrCombination((prevValue: any) => {
      let newValue = prevValue
      const existingValueIndex = Object.keys(prevValue).findIndex(
        (i: any) => i === key
      )
      newValue[key] = value
      if (existingValueIndex > -1) {
        newValue = Object.fromEntries(
          Object.entries(newValue).slice(0, existingValueIndex + 1)
        )
        return newValue
      }
      newValue = { ...newValue, [key]: value }
      return newValue
    })
  }

  const handleSelectedAttrData = (value: any) => {
    if (isCustomAttr) {
      if (
        Object.keys(attrCombination).length ===
        Object.keys(originalAttributes).length
      ) {
        const currentProduct = productLookup(
          variantProducts,
          attrCombination
        )[0]

        if (currentProduct) setSelectedAttrData(currentProduct)
      }
    } else setSelectedAttrData(value)
  }

  const DefaultComponent: any = () => null
  const stateAttributes: any = attrCombination

  return (
    <>
      {variantAttributes?.map((option: any, idx: number) => {
        const optionsToPass = generateOptions(option)
        const originalAttribute = isCustomAttr
          ? stateAttributes[option.fieldCode]
          : originalAttributes[option.fieldCode]

        const Component =
          (option?.inputType
            ? ATTR_COMPONENTS[option?.inputType]
            : TEMP_MAP[option?.fieldCode]) || TEMP_MAP[option?.fieldCode]

        return (
          <div
            key={idx}
            className={`py-3 hide-swach-padding flex-1 order_${option.fieldName.toLowerCase()}`}
          >
            <Component
              getStockPerAttribute={getCorrectStockPerAttribute}
              items={optionsToPass}
              label={option.fieldName}
              isDisabled={!optionsToPass.length}
              onChange={handleChange}
              setSelectedAttrData={handleSelectedAttrData}
              fieldCode={option.fieldCode}
              productId={product.id}
              setAttrCombination={handleAttrCombinations}
              generateLink={generateLink}
              product={product}
              selectedSizeProduct={selectedSizeProduct}
              selectedSizeProductDiscount={selectedSizeProductDiscount}
              variant={variant}
              callToAction={callToAction}
              allowCart={allowCart}
              currentVariantData={
                currentVariantData
                  ? Object.keys(currentVariantData).length
                    ? currentVariantData[option.fieldCode]
                    : undefined
                  : undefined
              }
              forInputSelection={forInputSelection}
              selectedValue={selectedValue}
              setQuickview={setQuickview}
              currentPage={currentPage}
              deviceInfo={deviceInfo}
            />
          </div>
        )
      })}
    </>
  )
}
