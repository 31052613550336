const Play = ({ ...props }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M12 3C7.03832 3 3 7.03832 3 12C3 16.9617 7.03832 21 12 21C16.9617 21 21 16.9617 21 12C21 7.03832 16.9617 3 12 3ZM12 4.5C16.151 4.5 19.5 7.84898 19.5 12C19.5 16.151 16.151 19.5 12 19.5C7.84898 19.5 4.5 16.151 4.5 12C4.5 7.84898 7.84898 4.5 12 4.5ZM15.7119 9L11.0244 13.6875L8.58691 11.25L7.53809 12.3003L11.0244 15.7881L16.7622 10.0503L15.7119 9Z"
        fill="black"
      />
    </svg>
  )
}

export default Play
